import { Component, Input, OnInit, EventEmitter, Inject, Output, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { MessagesService } from 'src/app/services/messages.service';
import { UtilsService } from 'src/app/services/utils.service';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-no-channel-selected',
    templateUrl: './no-channel-selected.component.html',
    styleUrls: ['./no-channel-selected.component.styl']
})
export class NoChannelSelectedComponent implements OnDestroy {
    onContinue = new EventEmitter()

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialog: MatDialog,
        private messagesApi: MessagesService,
        private utils: UtilsService
    ) {

    }

    ngOnDestroy(): void {
        
    }

    continue() {
        this.closeModal();
        this.onContinue.emit();
    }

    closeModal() {
        this.dialog.closeAll();
    }

    goToSelectResource() {
        this.closeModal();
        this.utils.scrollToVehicles();
    }
}