export const environment = {
  production: false,
  office: 'https://managerstaging.omnihunter.net',
  officeApi: 'https://managerstaging.omnihunter.net/api/v6',
  providerUrl: 'https://vehicleproviderstaging.omnihunter.net/',
  nodeApi: 'https://chatstaging.omnihunter.net/api/v2',
  socketUrl: 'https://chatstaging.omnihunter.net:443',
  agent: 'https://agentestaging.omnihunter.net',
  versionChat:'Versão 1.15.0',
  versionWhatsapp: 'Versão 4.0.9',
};
 
